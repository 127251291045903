.reactionTooltip {
  background: var(--background-primary);
  max-width: 288px;
  color: var(--text-primary);
  border-radius: 4px;
  padding: 16px;
  font-weight: 500;
  overflow: hidden;
}

.reactionTooltipEmoji {
  width: 32px;
  height: 32px;
}

.reactionTooltipText {
  color: var(--text-primary);
  margin-left: 12px;
  font-size: 14px;
  line-height: 1.28571;
}

.reactionTooltipInner {
  pointer-events: all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  align-items: center;
  display: flex;
}

.reactionTooltipInner:hover {
  cursor: pointer;
}

.reactionTooltipInner:hover a {
  text-decoration: underline;
}
