.skinTonePickerButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.cursorPointer {
  cursor: pointer;
}

.cursorText {
  cursor: text;
}

.skinTonePickerOptions {
  background-color: var(--background-secondary);
  border: 1px solid var(--background-tertiary);
  border-radius: 4px;
  flex-direction: column;
  width: auto;
  height: 192px;
  display: flex;
  position: absolute;
  top: 0;
  right: 11px;
}

.skinTonePickerItem {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  padding: 4px;
  transition: background-color .1s ease-in-out;
}

.skinTonePickerItemImage {
  width: 24px;
  height: 24px;
}

.emojiPicker {
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 48px auto;
  width: auto;
  height: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

.bodyWrapper {
  grid-area: 2 / 2 / 3 / 3;
  grid-template-rows: auto 1fr;
  display: grid;
  position: relative;
}

.emojiPickerListWrapper {
  grid-row: 2 / 3;
  position: relative;
  overflow: hidden;
}

.listWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.list {
  height: 100%;
}

.header {
  z-index: 100;
  grid-column: 1 / 3;
  align-items: center;
  padding: 0 16px 16px;
  display: flex;
  position: relative;
}

.searchBar {
  box-sizing: border-box;
  background-color: var(--background-tertiary);
  border-radius: 4px;
  flex: 1;
  margin-right: 12px;
  display: flex;
  overflow: hidden;
}

.searchBarInner {
  box-sizing: border-box;
  flex-flow: wrap;
  flex: auto;
  padding: 1px;
  display: flex;
  position: relative;
}

.searchBarInput {
  box-sizing: border-box;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 48px;
  height: 30px;
  color: var(--text-tertiary);
  background: none;
  border: none;
  flex: 1;
  margin: 1px;
  padding: 0 8px;
  font-size: 16px;
  line-height: 32px;
}

.searchBarInput::placeholder {
  color: var(--text-primary-muted);
}

.iconLayout {
  box-sizing: border-box;
  cursor: text;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.iconContainer {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  position: relative;
}

.icon {
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  color: var(--text-tertiary-muted);
  transition: transform .1s ease-out, opacity .1s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.icon.visible {
  opacity: 1;
  transform: rotate(0);
}

.iconSize {
  width: 24px;
  height: 24px;
}

.inspector {
  box-sizing: border-box;
  background-color: var(--background-primary);
  grid-area: 3 / 2 / 4 / 3;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.inspectorEmoji {
  width: 28px;
  height: 28px;
}

.inspectorText {
  margin-left: 8px;
}

.categoryList {
  background: var(--background-primary);
  border-radius: 0 0 0 8px;
  grid-template-rows: 1fr auto;
  width: 48px;
  display: grid;
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.listItems {
  contain: layout;
  flex-direction: column;
  flex: auto;
  padding: 0 8px;
  display: flex;
}

.categoryListIcon {
  cursor: pointer;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  transition: background-color .2s, color .2s;
  display: flex;
}

.categoryListIcon:hover, .categoryListIconActive {
  background-color: var(--background-modifier-hover);
  color: var(--text-primary);
}

.textPrimaryMuted {
  color: var(--text-primary-muted);
}

.emojiGrid {
  grid-template-columns: repeat(9, 1fr);
  display: grid;
}

.emojiGridCategory {
  margin-bottom: 16px;
}

.categoryTitle {
  color: var(--text-primary-muted);
  margin-bottom: 8px;
  font-size: .875rem;
  font-weight: bold;
}

.emojiRenderer {
  cursor: pointer;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  transition: background-color .2s, color .2s;
  display: flex;
}

.emojiRenderer:hover {
  background-color: var(--background-modifier-selected);
}

.emojiImage {
  width: 40px;
  height: 40px;
}
