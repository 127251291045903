.scroller::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.scroller:-webkit-scrollbar-corner {
  background-color: #0000;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: var(--background-header-secondary);
  min-height: 40px;
}

.scrollerWithBottomGutter::-webkit-scrollbar-track {
  margin-bottom: 8px;
}

.scroller::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 4px solid #0000;
  border-radius: 8px;
}

.scroller::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: 4px solid #0000;
  border-radius: 8px;
}

.scroller::-webkit-scrollbar-track {
  background-color: var(--background-secondary);
}
