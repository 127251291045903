.channelAttachmentArea {
	display: flex;
	gap: 24px;
	margin: 0 0 2px 6px;
	padding: 20px 10px 10px;
	overflow-x: auto;
}

.upload {
	display: inline-flex;
	flex-direction: column;
	background: var(--background-primary);
	border-radius: 4px;
	margin: 0;
	padding: 8px;
	position: relative;
	min-width: 200px;
	max-width: 200px;
	min-height: 200px;
	max-height: 200px;
}

.uploadContainer {
	display: flex;
	position: relative;
	flex-direction: column;
	height: 100%;
}

.mediaContainer {
	margin-top: auto;
	position: relative;
	min-height: 0;
	background-color: var(--background-tertiary);
}

.clickableMedia {
	cursor: pointer;
}

.mediaContainer > div:not([aria-expanded='false']),
.mediaContainer > div:not([aria-expanded='false']) > div {
	height: 100%;
}

.spoilerContainer {
	height: 100%;
	position: relative;
	filter: blur(0);
	border-radius: 4px;
	background-color: hsla(0, 0%, 100%, 0.1);
}

.spoilerContainer.hidden {
	overflow: hidden;
}

.spoilerContainer.hiddenSpoiler {
	cursor: pointer;
}

.spoilerWarning {
	text-transform: uppercase;
	font-size: 15px;
	background-color: hsla(0, 0%, 0%, 0.6);
	cursor: pointer;
	font-weight: 600;
	border-radius: 20px;
	transition: background-color 0.2s;
}

.spoilerWarning:hover {
	background-color: hsla(0, 0%, 0%, 0.8);
}

.obscureWarning {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	padding: 8px 12px;
	user-select: none;
	color: hsl(216, 10%, 90%);
}

.spoilerInnerContainer {
	width: 100%;
	height: 100%;
}

.spoilerWrapper {
	display: flex;
	justify-content: center;
	height: 100%;
}

.spoiler {
	filter: blur(44px);
	pointer-events: none;
}

.media {
	border-radius: 4px;
	max-width: 100%;
	object-fit: contain;
}

.tags {
	position: absolute;
	left: 3px;
	bottom: 6px;
}

.filenameContainer {
	margin-top: auto;
}

.filename {
	margin-top: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
	line-height: 1.28571;
	color: var(--text-primary);
}

.actionBarContainer {
	position: absolute;
	top: 0;
	right: 0;
}

.actionBar {
	display: grid;
	position: relative;
	z-index: 1;
	transform: translate(25%, -25%);
	box-sizing: border-box;
	align-items: center;
	justify-content: flex-start;
	grid-auto-flow: column;
	padding: 2px;
	background-color: var(--background-primary);
	border: 1px solid var(--background-header-secondary);
	border-radius: 8px;
	user-select: none;
}

.button {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	padding: 4px;
	height: 30px;
	min-width: 30px;
	border-radius: 6px;
	color: var(--text-tertiary);
	cursor: pointer;
}

.button:hover {
	color: var(--text-primary);
	background-color: var(--background-modifier-hover);
}

.button.danger {
	color: var(--status-danger);
}

.actionBarIcon {
	width: 20px;
	height: 20px;
	display: block;
	object-fit: contain;
}

.altTag {
	color: black;
	mix-blend-mode: screen;
	background: var(--text-primary);
	margin-right: 4px;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 600;
	padding: 4px;
	border-radius: 4px;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.iconImage {
	height: 100px;
	color: var(--brand-primary-light);
}

.loadingOverlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: hsla(0, 0%, 0%, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
}

.spinner {
	width: 40px;
	height: 40px;
	border: 4px solid hsla(0, 0%, 100%, 0.3);
	border-radius: 50%;
	border-top-color: hsl(0, 0%, 100%);
	animation: spin 1s ease infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
