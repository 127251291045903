.statusIcon-online {
	background-color: var(--status-online);
}

.statusIcon-offline {
	background-color: var(--status-offline);
}

.statusIcon-dnd {
	background-color: var(--status-dnd);
}

.statusIcon-idle {
	background-color: var(--status-idle);
}

.statusPopoutItem:hover .statusIcon {
	background-color: var(--brand-primary-fill);
}
