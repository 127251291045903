.embed {
  box-sizing: border-box;
  background: var(--background-primary);
  border-radius: 4px;
  max-width: max-content;
  display: grid;
  position: relative;
}

.embedFull {
  border-left: 4px solid var(--background-tertiary);
  background: var(--background-primary);
}

.gridContainer {
  width: 100%;
}

.grid {
  grid-template-rows: auto;
  grid-template-columns: auto;
  padding: 8px 16px 16px 12px;
  display: grid;
  overflow: hidden;
}

.grid.hasThumbnail {
  grid-template-columns: auto min-content;
}

.embedContent {
  flex-direction: column;
  gap: 8px;
  min-width: 0;
  display: flex;
}

.embedContent > :first-child {
  margin-top: 8px;
}

.embedProvider {
  grid-column: 1 / 1;
  font-size: .75rem;
  line-height: 1rem;
}

.embedProvider .embedLink {
  color: var(--interactive-normal);
}

.embedProvider .embedLink:hover {
  color: var(--interactive-hover);
}

.embedAuthor {
  grid-column: 1 / 1;
  align-items: center;
  min-width: 0;
  display: flex;
}

.embedAuthorName {
  color: var(--text-chat);
  font-size: .875rem;
  font-weight: 600;
}

.embedAuthorNameLink {
  color: var(--text-normal) !important;
}

.embedAuthorIcon {
  object-fit: contain;
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.embedTitle {
  color: var(--embed-title);
  grid-column: 1 / 1;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.embedLink {
  -webkit-text-decoration: var(--link-decoration);
  text-decoration: var(--link-decoration);
  cursor: pointer;
  color: var(--text-link);
}

.embedLink:hover {
  text-decoration: underline;
}

.embedDescription {
  white-space: pre-line;
  color: var(--text-chat);
  grid-column: 1 / 1;
  font-size: .875rem;
  line-height: 1.125rem;
}

.embedFields {
  grid-column: 1 / 1;
  gap: 8px;
  min-width: 0;
  margin-top: 8px;
  display: grid;
}

.embedField {
  min-width: 0;
  font-size: .875rem;
  line-height: 1.125rem;
}

.embedFieldName {
  min-width: 0;
  color: var(--embed-title);
  margin-bottom: 2px;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.125rem;
}

.embedFieldValue {
  white-space: pre-line;
  min-width: 0;
  color: var(--text-chat);
  font-size: .875rem;
  line-height: 1.125rem;
}

.embedMedia {
  contain: paint;
  border-radius: 4px;
  grid-column: 1 / 1;
  margin-top: 8px;
  overflow: hidden;
}

.hasThumbnail .embedMedia {
  grid-column: 1 / 3;
}

.embedThumbnail {
  border-radius: 4px;
  flex-shrink: 0;
  grid-area: 1 / 2 / 8 / 2;
  justify-self: end;
  max-width: 80px;
  height: 80px;
  margin-top: 8px;
  margin-left: 16px;
  overflow: hidden;
}

.embedFooter {
  grid-column: 1 / 1;
  align-items: center;
  display: flex;
}

.hasThumbnail .embedFooter {
  grid-column: 1 / 3;
}

.embedFooterText {
  color: var(--text-chat);
  text-overflow: ellipsis;
  min-width: 0;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
  overflow: hidden;
}

.embedFooterIcon {
  object-fit: contain;
  border-radius: 50%;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.embedFooterSeparator {
  background-color: var(--text-chat);
  vertical-align: middle;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 0 4px;
  display: inline-block;
}

.embedFull {
  background: var(--background-primary);
  border: 1px solid var(--background-header-secondary);
  border-left: 4px solid var(--border-normal);
}
