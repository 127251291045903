.channelAttachmentArea {
  gap: 24px;
  margin: 0 0 2px 6px;
  padding: 20px 10px 10px;
  display: flex;
  overflow-x: auto;
}

.upload {
  background: var(--background-primary);
  border-radius: 4px;
  flex-direction: column;
  min-width: 200px;
  max-width: 200px;
  min-height: 200px;
  max-height: 200px;
  margin: 0;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.uploadContainer {
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
}

.mediaContainer {
  background-color: var(--background-tertiary);
  min-height: 0;
  margin-top: auto;
  position: relative;
}

.clickableMedia {
  cursor: pointer;
}

.mediaContainer > div:not([aria-expanded="false"]), .mediaContainer > div:not([aria-expanded="false"]) > div {
  height: 100%;
}

.spoilerContainer {
  filter: blur();
  background-color: #ffffff1a;
  border-radius: 4px;
  height: 100%;
  position: relative;
}

.spoilerContainer.hidden {
  overflow: hidden;
}

.spoilerContainer.hiddenSpoiler {
  cursor: pointer;
}

.spoilerWarning {
  text-transform: uppercase;
  cursor: pointer;
  background-color: #0009;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 600;
  transition: background-color .2s;
}

.spoilerWarning:hover {
  background-color: #000c;
}

.obscureWarning {
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  color: #e3e5e8;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spoilerInnerContainer {
  width: 100%;
  height: 100%;
}

.spoilerWrapper {
  justify-content: center;
  height: 100%;
  display: flex;
}

.spoiler {
  filter: blur(44px);
  pointer-events: none;
}

.media {
  object-fit: contain;
  border-radius: 4px;
  max-width: 100%;
}

.tags {
  position: absolute;
  bottom: 6px;
  left: 3px;
}

.filenameContainer {
  margin-top: auto;
}

.filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-primary);
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.28571;
  overflow: hidden;
}

.actionBarContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.actionBar {
  z-index: 1;
  box-sizing: border-box;
  background-color: var(--background-primary);
  border: 1px solid var(--background-header-secondary);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 8px;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  display: grid;
  position: relative;
  transform: translate(25%, -25%);
}

.button {
  min-width: 30px;
  height: 30px;
  color: var(--text-tertiary);
  cursor: pointer;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
  position: relative;
}

.button:hover {
  color: var(--text-primary);
  background-color: var(--background-modifier-hover);
}

.button.danger {
  color: var(--status-danger);
}

.actionBarIcon {
  object-fit: contain;
  width: 20px;
  height: 20px;
  display: block;
}

.altTag {
  color: #000;
  mix-blend-mode: screen;
  background: var(--text-primary);
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 4px;
  padding: 4px;
  font-size: 10px;
  font-weight: 600;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.iconImage {
  height: 100px;
  color: var(--brand-primary-light);
}

.loadingOverlay {
  background: #00000080;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.spinner {
  border: 4px solid #ffffff4d;
  border-top-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: 1s infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
