.theme-light .markup {
	font-weight: 500;
}

.blockquoteContainer {
	display: flex;
	margin: 2px 0;
	width: 100%;
}

.blockquoteDivider {
	width: 4px;
	background-color: var(--interactive-muted);
	border-radius: 4px;
	flex-shrink: 0;
}

.blockquoteContent {
	padding: 0 8px 0 12px;
	box-sizing: border-box;
	text-indent: 0;
	width: 100%;
}

.emoji {
	object-fit: contain;
	width: 1.375em;
	height: 1.375em;
	position: static;
	vertical-align: -0.35em;
	cursor: pointer;
	display: inline-block;
}

.emoji.jumboable {
	width: 3rem;
	height: 3rem;
	min-height: 3rem;
	vertical-align: middle;
}

.hljs {
	display: block;
	overflow-x: auto;
	padding: 0.5em;
	border-radius: 4px;
	text-size-adjust: none;
	background: var(--custom-code-bg, var(--background-primary));
}

.interactive {
	transition: background-color 50ms ease-out, color 50ms ease-out;
	cursor: pointer;
}

.interactive:hover {
	color: var(--markup-interactive-hover-text);
	background-color: var(--markup-interactive-hover-fill);
}

.markup .codeActions {
	position: absolute;
	display: none;
	right: 4px;
	top: 8px;
}

.markup .codeActions > div {
	cursor: pointer;
}

.markup .codeContainer {
	position: relative;
}

.markup .codeContainer:hover .codeActions {
	display: block;
}

.markup .no-webkit-scrollbar code {
	scrollbar-width: thin;
	scrollbar-color: var(--background-tertiary) var(--background-primary);
}

.link {
	color: var(--text-link);
	word-break: break-word;
	text-decoration: var(--link-decoration);
	cursor: pointer;
}

.link:hover {
	text-decoration: underline;
}

.markup blockquote pre {
	max-width: 100%;
}

.markup blockquote {
	max-width: 90%;
	box-sizing: border-box;
	text-indent: 0;
}

.markup code {
	font-size: 0.875rem;
	line-height: 1.125rem;
	text-indent: 0;
	white-space: pre-wrap;
	background: var(--custom-code-bg, var(--background-primary));
}

.markup code.inline {
	padding: 0 0.2em;
	margin: -0.2em 0;
	border-radius: 4px;
	font-size: 85%;
	font-family: var(--font-mono);
	text-indent: 0;
	white-space: pre-wrap;
}

.markup em {
	font-style: italic;
}

.markup h1 {
	font-size: 1.5rem;
	margin: 16px 0 8px;
}

.markup h1,
.markup h2,
.markup h3,
.markup h4,
.markup h5,
.markup h6 {
	font-weight: 700;
	color: var(--header-primary);
	line-height: 1.375em;
	margin: 16px 0 4px;
}

.markup h1.inlineFormat,
.markup h2.inlineFormat,
.markup h3.inlineFormat,
.markup h4.inlineFormat,
.markup h5.inlineFormat,
.markup h6.inlineFormat {
	margin: 0 !important;
	font-weight: 600;
	font-size: inherit;
	white-space: normal;
	padding-right: 16px;
	line-height: 1.25em;
	color: inherit;
	display: inline;
}

.markup h1:first-child,
.markup h2:first-child {
	margin-top: 8px;
}

.markup h2 {
	font-size: 1.25rem;
	margin: 16px 0 8px;
}

.markup h3 {
	font-size: 1rem;
	margin: 16px 0 8px;
}

.markup h3:first-child,
.markup h4:first-child,
.markup h5:first-child,
.markup h6:first-child {
	margin-top: 4px;
}

.markup li {
	white-space: break-spaces;
	margin-bottom: 4px;
}

.markup ol ol,
.markup ul ol {
	margin-bottom: 0;
}

.markup ol {
	list-style-type: decimal;
	margin-left: calc(0.4em + 0.6em * var(--totalCharacters));
}

.markup pre {
	box-sizing: border-box;
	border-radius: 4px;
	padding: 0;
	font-family: var(--font-mono);
	font-size: 0.75rem;
	line-height: 1rem;
	margin-top: 6px;
	max-width: 90%;
	white-space: pre-wrap;
	background-clip: border-box;
}

.markup small.inlineFormat:after {
	content: \\\' \\\';
}

.markup small:not(.inlineFormat) {
	display: block;
	color: var(--text-chat-muted);
	font-size: 0.8125rem;
	line-height: 1.11719rem;
}

.markup strong {
	font-weight: 700;
}

.markup ul ul,
.markup ol ul {
	list-style-type: circle;
	margin-bottom: 0;
}

.markup ul {
	list-style-type: disc;
}

.markup ul,
.markup ol {
	margin: 4px 0 0 16px;
	list-style-position: outside;
}

.markup ul.inlineFormat,
.markup ol.inlineFormat {
	display: inline;
	margin: 0;
	padding: 0 8px 0 0;
}

.markup {
	font-size: 1rem;
	line-height: 1.5rem;
	white-space: pre-wrap;
	white-space: break-spaces;
	word-wrap: break-word;
	user-select: text;
	color: var(--text-chat);
}

.markup.inlineFormat li li:before,
.markup .inlineFormat li li:before {
	content: '•';
	font-size: 0.625rem;
	padding: 0 4px 4px;
	line-height: 1rem;
}

.markup.inlineFormat li,
.markup .inlineFormat li {
	display: inline;
	margin: 0;
	padding-right: 8px;
}

.markup.inlineFormat li:before,
.markup .inlineFormat li:before {
	content: '•';
	padding-right: 4px;
}

.mention {
	border-radius: 4px;
	padding: 0 2px;
	font-weight: 500;
	unicode-bidi: plaintext;
	color: var(--markup-mention-text);
	background-color: var(--markup-mention-fill);
}

.bio {
	font-size: 14px;
	line-height: 1.28571;
}

.tableContainer {
	margin: 8px 0;
	max-width: 100%;
	overflow-x: auto;
	border-radius: 8px;
	border: 1px solid var(--background-tertiary);
	background: var(--background-secondary);
	padding: 1px;
}

.table {
	border-spacing: 0;
	border-collapse: separate;
	border-radius: 8px;
	margin: 0;
	width: 100%;
	box-sizing: border-box;
	font-size: 0.925rem;
}

.tableCell {
	border: 1px solid var(--background-tertiary);
	border-right: 0;
	border-bottom: 0;
	padding: 12px 16px;
	text-align: left;
	background: var(--background-primary);
	vertical-align: middle;
	line-height: 1.5;
}

.tableCell:last-child {
	border-right: 1px solid var(--background-tertiary);
}

.table tbody tr:last-child .tableCell {
	border-bottom: 1px solid var(--background-tertiary);
}

.tableHeader {
	background: var(--background-secondary);
	font-weight: 600;
	color: var(--text-primary);
	border-bottom: 2px solid var(--background-tertiary);
}

.tableHeader:first-child {
	border-top-left-radius: 8px;
}

.tableHeader:last-child {
	border-top-right-radius: 8px;
}

.table tbody tr:nth-child(odd) .tableCell {
	background: var(--background-secondary);
}

.table tbody tr:nth-child(even) .tableCell {
	background: var(--background-secondary-alt);
}

.table tbody tr:hover .tableCell {
	background: var(--background-modifier-hover);
	transition: background-color 0.15s ease;
}

.table tbody tr:last-child .tableCell:first-child {
	border-bottom-left-radius: 8px;
}

.table tbody tr:last-child .tableCell:last-child {
	border-bottom-right-radius: 8px;
}

.alignLeft {
	text-align: left;
}

.alignCenter {
	text-align: center;
}

.alignRight {
	text-align: right;
}

.tableCell code.inline {
	background: var(--background-tertiary);
	color: var(--text-primary);
	padding: 0.2em 0.4em;
	border-radius: 4px;
}

.tableCell ul,
.tableCell ol {
	margin: 0;
	padding-left: 20px;
}

.alert {
	display: flex;
	align-items: center;
	border-radius: 0.375rem;
	padding: 1rem;
	margin: 0.25rem 0;
	border-left: 4px solid;
	min-height: 3rem;
}

.alertIcon {
	flex-shrink: 0;
	margin-right: 0.75rem;
	width: 1.25rem;
	height: 1.25rem;
}

.alertContent {
	flex-grow: 1;
	min-width: 0;
}

.alertNote {
	background-color: rgba(56, 139, 253, 0.1);
	border-left-color: #388bfd;
	color: var(--text-chat);
}

.alertNote .alertIcon {
	color: #388bfd;
}

.alertTip {
	background-color: rgba(46, 160, 67, 0.1);
	border-left-color: #2ea043;
	color: var(--text-chat);
}

.alertTip .alertIcon {
	color: #2ea043;
}

.alertImportant {
	background-color: rgba(170, 0, 255, 0.1);
	border-left-color: #aa00ff;
	color: var(--text-chat);
}

.alertImportant .alertIcon {
	color: #aa00ff;
}

.alertWarning {
	background-color: rgba(227, 153, 18, 0.1);
	border-left-color: #e39912;
	color: var(--text-chat);
}

.alertWarning .alertIcon {
	color: #e39912;
}

.alertCaution {
	background-color: rgba(248, 81, 73, 0.1);
	border-left-color: #f85149;
	color: var(--text-chat);
}

.alertCaution .alertIcon {
	color: #f85149;
}

.timestamp {
	background-color: var(--background-modifier-hover);
	border-radius: 3px;
	padding: 0 2px;
	white-space: nowrap;
}

:root {
	--spoiler-overlay-color: rgba(0, 0, 0, 0.2);
	--spoiler-overlay-hover-color: rgba(0, 0, 0, 0.3);
	--spoiler-border-radius: 6px;
}

.spoiler {
	padding: 0 2px;
}

.blockSpoiler {
	padding: 8px;
}

.spoiler:not([data-revealed='true']) {
	cursor: pointer;
	background-color: var(--spoiler-overlay-color);
	position: relative;
	border-radius: var(--spoiler-border-radius);
	overflow: hidden;
}

.blockSpoiler:not([data-revealed='true']) {
	cursor: pointer;
	background-color: var(--spoiler-overlay-color);
	position: relative;
	border-radius: var(--spoiler-border-radius);
	overflow: hidden;
}

.spoiler:not([data-revealed='true']) .spoilerContent,
.blockSpoiler:not([data-revealed='true']) .spoilerContent {
	opacity: 0;
	pointer-events: none;
	user-select: none;
	transition: opacity 0.2s ease;
	color: transparent;
	filter: blur(5px);
}

.spoiler:not([data-revealed='true']):hover,
.blockSpoiler:not([data-revealed='true']):hover {
	background-color: var(--spoiler-overlay-hover-color);
}

.spoiler[data-revealed='true'] {
	background-color: var(--background-modifier-hover);
	border-radius: var(--spoiler-border-radius);
}

.blockSpoiler[data-revealed='true'] {
	background-color: var(--background-modifier-hover);
	border-radius: var(--spoiler-border-radius);
}

.spoiler[data-revealed='true'] .spoilerContent,
.blockSpoiler[data-revealed='true'] .spoilerContent {
	opacity: 1;
	filter: none;
	transition: opacity 0.2s ease, filter 0.2s ease;
}

.spoiler .spoiler,
.blockSpoiler .spoiler,
.spoiler .blockSpoiler,
.blockSpoiler .blockSpoiler {
	border-radius: var(--spoiler-border-radius);
}

.blockSpoiler .spoilerContent > :first-child {
	margin-top: 0;
}

.blockSpoiler .spoilerContent > :last-child {
	margin-bottom: 0;
}
