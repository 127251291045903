.message .buttons {
	opacity: 0;
	pointer-events: none;
}

.message:hover .buttons,
.message.selected .buttons,
.message .buttons.emojiPickerOpen {
	opacity: 1;
	pointer-events: auto;
}

.message .timestampVisibleOnHover {
	opacity: 0;
	pointer-events: none;
}

.message:hover .timestampVisibleOnHover {
	opacity: 1;
	pointer-events: auto;
}

.messageSent {
	color: var(--text-chat);
}

.messageSending {
	color: var(--text-chat-muted);
}

.messageSending a {
	opacity: 0.7;
}

.messageFailed {
	color: var(--status-danger);
}

.messageFailed a {
	color: var(--status-danger);
}

.container {
	display: grid;
	height: fit-content;
	grid-auto-flow: row;
	row-gap: 0.25rem;
	grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
	text-indent: 0;
	min-height: 0;
	min-width: 0;
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
	position: relative;
}

.container:empty {
	display: none;
}

.container > * {
	justify-self: start;
	align-self: start;
}

.repliedMessage {
	--reply-spacing: 4px;
	margin-bottom: var(--reply-spacing);
}

.repliedMessage:before {
	--avatar-size: 40px;
	--gutter: 16px;
	--spine-width: 2px;
	content: '';
	display: block;
	position: absolute;
	box-sizing: border-box;
	top: 50%;
	right: 100%;
	bottom: 0;
	left: calc(-1 * (0.5 * var(--avatar-size) + var(--gutter)));
	margin-right: var(--reply-spacing);
	margin-top: calc(-0.5 * var(--spine-width));
	margin-left: calc(-0.5 * var(--spine-width));
	margin-bottom: calc(-4px + 0.125rem);
	border-color: var(--text-chat-muted);
	border-width: var(--spine-width) 0 0 var(--spine-width);
	border-style: solid;
	border-top-left-radius: 6px;
}

.repliedTextPreview {
	display: flex;
	flex: 0 1 auto;
	overflow: hidden;
}

.repliedTextPreview,
.repliedTextContent {
	display: -webkit-inline-box !important;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
	width: 100%;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	user-select: none;
}

.messageContent {
	--message-margin-left-content: calc(40px + 16px + 16px);
	user-select: text;
	margin-left: calc(-1 * var(--message-margin-left-content));
	padding-left: var(--message-margin-left-content);
	overflow: hidden;
	pointer-events: none;
}
