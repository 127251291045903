.actionBarContainer {
  z-index: var(--z-index-elevated-1);
  padding: 0 14px 0 32px;
  position: absolute;
  top: -16px;
  right: 0;
}

.actionBar {
  box-sizing: border-box;
  background-color: var(--background-primary);
  border: 1px solid var(--background-header-secondary);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 8px;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  display: grid;
  position: relative;
}

.button {
  min-width: 30px;
  height: 30px;
  color: var(--text-tertiary);
  cursor: pointer;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
  position: relative;
}

.button:hover, .button.active {
  color: var(--text-primary);
  background-color: var(--background-modifier-hover);
}

.button.danger {
  color: var(--status-danger);
}

.actionBarIcon {
  object-fit: contain;
  width: 20px;
  height: 20px;
  display: block;
}
