.embed {
	position: relative;
	display: grid;
	max-width: max-content;
	box-sizing: border-box;
	border-radius: 4px;
	background: var(--background-primary);
}

.embedFull {
	border-left: 4px solid var(--background-tertiary);
	background: var(--background-primary);
}

.gridContainer {
	width: 100%;
}

.grid {
	overflow: hidden;
	padding: 8px 16px 16px 12px;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto;
}

.grid.hasThumbnail {
	grid-template-columns: auto min-content;
}

.embedContent {
	min-width: 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.embedContent > *:first-child {
	margin-top: 8px;
}

.embedProvider {
	font-size: 0.75rem;
	line-height: 1rem;
	grid-column: 1 / 1;
}

.embedProvider .embedLink {
	color: var(--interactive-normal);
}

.embedProvider .embedLink:hover {
	color: var(--interactive-hover);
}

.embedAuthor {
	display: flex;
	align-items: center;
	grid-column: 1 / 1;
	min-width: 0;
}

.embedAuthorName {
	font-size: 0.875rem;
	font-weight: 600;
	color: var(--text-chat);
}

.embedAuthorNameLink {
	color: var(--text-normal) !important;
}

.embedAuthorIcon {
	flex-shrink: 0;
	margin-right: 8px;
	width: 24px;
	height: 24px;
	object-fit: contain;
	border-radius: 50%;
}

.embedTitle {
	font-size: 1rem;
	font-weight: 600;
	display: inline-block;
	grid-column: 1 / 1;
	color: var(--embed-title);
}

.embedLink {
	text-decoration: var(--link-decoration);
	cursor: pointer;
	color: var(--text-link);
}

.embedLink:hover {
	text-decoration: underline;
}

.embedDescription {
	font-size: 0.875rem;
	line-height: 1.125rem;
	white-space: pre-line;
	grid-column: 1 / 1;
	color: var(--text-chat);
}

.embedFields {
	display: grid;
	grid-column: 1 / 1;
	gap: 8px;
	min-width: 0;
	margin-top: 8px;
}

.embedField {
	font-size: 0.875rem;
	line-height: 1.125rem;
	min-width: 0;
}

.embedFieldName {
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 600;
	margin-bottom: 2px;
	min-width: 0;
	color: var(--embed-title);
}

.embedFieldValue {
	font-size: 0.875rem;
	line-height: 1.125rem;
	white-space: pre-line;
	min-width: 0;
	color: var(--text-chat);
}

.embedMedia {
	grid-column: 1 / 1;
	border-radius: 4px;
	contain: paint;
	margin-top: 8px;
	overflow: hidden;
}

.hasThumbnail .embedMedia {
	grid-column: 1 / 3;
}

.embedThumbnail {
	grid-row: 1 / 8;
	grid-column: 2 / 2;
	margin-left: 16px;
	margin-top: 8px;
	flex-shrink: 0;
	justify-self: end;
	max-width: 80px;
	height: 80px;
	border-radius: 4px;
	overflow: hidden;
}

.embedFooter {
	display: flex;
	align-items: center;
	grid-column: 1 / 1;
}

.hasThumbnail .embedFooter {
	grid-column: 1 / 3;
}

.embedFooterText {
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 500;
	color: var(--text-chat);
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.embedFooterIcon {
	flex-shrink: 0;
	margin-right: 8px;
	width: 20px;
	height: 20px;
	object-fit: contain;
	border-radius: 50%;
}

.embedFooterSeparator {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: var(--text-chat);
	margin: 0 4px;
	display: inline-block;
	vertical-align: middle;
}

.embedFull {
	background: var(--background-primary);
	border: 1px solid var(--background-header-secondary);
	border-left: 4px solid var(--border-normal);
}
