.clickable {
  cursor: pointer;
}

.clickable:hover .overlay:after {
  opacity: .4;
}

.clickable:has(:focus-visible) .overlay:after {
  opacity: .4;
}

.overlay:after {
  content: "";
  z-index: 0;
  opacity: 0;
  background-color: #000;
  border-radius: 9999px;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  right: 0;
}
