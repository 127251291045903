.actionBarContainer {
	position: absolute;
	top: -16px;
	right: 0;
	z-index: var(--z-index-elevated-1);
	padding: 0 14px 0 32px;
}

.actionBar {
	display: grid;
	position: relative;
	box-sizing: border-box;
	align-items: center;
	justify-content: flex-start;
	grid-auto-flow: column;
	padding: 2px;
	background-color: var(--background-primary);
	border: 1px solid var(--background-header-secondary);
	border-radius: 8px;
	user-select: none;
}

.button {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	padding: 4px;
	height: 30px;
	min-width: 30px;
	border-radius: 6px;
	color: var(--text-tertiary);
	cursor: pointer;
}

.button:hover,
.button.active {
	color: var(--text-primary);
	background-color: var(--background-modifier-hover);
}

.button.danger {
	color: var(--status-danger);
}

.actionBarIcon {
	width: 20px;
	height: 20px;
	display: block;
	object-fit: contain;
}
