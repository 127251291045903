@keyframes blink {
  50% {
    fill: #0000;
  }
}

.dot {
  fill: var(--text-chat);
  animation: 1s infinite blink;
}

.dot:nth-child(2) {
  animation-delay: .25s;
}

.dot:nth-child(3) {
  animation-delay: .5s;
}
