.reactionTooltip {
	max-width: 288px;
	padding: 16px;
	overflow: hidden;
	background: var(--background-primary);
	font-weight: 500;
	color: var(--text-primary);
	border-radius: 4px;
}

.reactionTooltipEmoji {
	width: 32px;
	height: 32px;
}

.reactionTooltipText {
	margin-left: 12px;
	font-size: 14px;
	line-height: 1.28571;
	color: var(--text-primary);
}

.reactionTooltipInner {
	pointer-events: all;
	display: flex;
	align-items: center;
	word-break: break-word;
	hyphens: auto;
}

.reactionTooltipInner:hover {
	cursor: pointer;
}

.reactionTooltipInner:hover a {
	text-decoration: underline;
}
