.tooltip {
  z-index: var(--z-index-tooltip);
  pointer-events: none;
  will-change: opacity, transform;
  box-sizing: border-box;
  border: 1px solid var(--background-header-secondary);
  max-width: 190px;
  color: var(--text-primary);
  word-wrap: break-word;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  position: relative;
}

.tooltipPointer {
  pointer-events: none;
  border: 5px solid #0000;
  border-top-color: var(--tooltip-pointer-bg);
  width: 0;
  height: 0;
}

.tooltipContent {
  padding: 8px 12px;
  overflow: hidden;
}

.tooltipContentAllowOverflow {
  overflow: visible;
}

.tooltipTop {
  transform-origin: 50% 100%;
}

.tooltipTop .tooltipPointer {
  margin-left: -5px;
  position: absolute;
  top: 100%;
  left: 50%;
}

.tooltipCenter, .tooltipBottom {
  transform-origin: 50% 0;
}

.tooltipCenter .tooltipPointer, .tooltipBottom .tooltipPointer {
  border-top-width: 5px;
  margin-left: -5px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: rotate(180deg);
}

.tooltipLeft {
  transform-origin: 100%;
}

.tooltipLeft .tooltipPointer {
  border-right-width: 5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: rotate(270deg);
}

.tooltipRight {
  transform-origin: 0%;
}

.tooltipRight .tooltipPointer {
  border-left-width: 5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: rotate(90deg);
}

.tooltipPrimary {
  --tooltip-pointer-bg: var(--background-primary);
  background-color: var(--background-primary);
}

.tooltipPrimary .tooltipPointer {
  border-top-color: var(--background-primary);
}

.tooltipPrimary .tooltipPointer.tooltipPointerBg {
  --tooltip-pointer-bg: var(--background-header-secondary);
  border-top-color: var(--background-header-secondary);
}

.tooltipPrimary .tooltipPointer:not(.tooltipPointerBg) {
  border-top-color: var(--background-primary);
  z-index: 1;
}

.tooltipRed {
  --tooltip-pointer-bg: var(--status-danger);
  background-color: var(--status-danger);
}

.tooltipRed .tooltipPointer {
  border-top-color: var(--status-danger);
}

.tooltipPointer:not(.tooltipPointerBg) {
  z-index: 1;
}

.tooltipTop .tooltipPointer:not(.tooltipPointerBg) {
  margin-top: -1.5px;
}

.tooltipLeft .tooltipPointer:not(.tooltipPointerBg) {
  margin-left: -1.5px;
}

.tooltipRight .tooltipPointer:not(.tooltipPointerBg) {
  margin-right: -1.5px;
}

.tooltipBottom .tooltipPointer:not(.tooltipPointerBg), .tooltipCenter .tooltipPointer:not(.tooltipPointerBg) {
  margin-bottom: -1.5px;
}

.tooltipPointer.tooltipPointerBg {
  --border-width: 5px;
}

.tooltipPointer.tooltipPointerBg:before, .tooltipPointer.tooltipPointerBg:after {
  content: "";
  border-left: var(--border-width) solid transparent;
  border-right: var(--border-width) solid transparent;
  margin-left: calc(-1 * var(--border-width));
  position: absolute;
  top: 100%;
  left: 50%;
}

.tooltipPointer.tooltipPointerBg:before {
  border-top: var(--border-width) solid var(--tooltip-pointer-bg);
  margin-top: -5px;
}

.tooltipPointer.tooltipPointerBg:after {
  border-top: var(--border-width) solid var(--background-header-secondary);
  z-index: 1;
  margin-top: -5px;
}

.tooltips {
  pointer-events: none;
  z-index: var(--z-index-tooltip);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none !important;
}
