@keyframes blink {
	50% {
		fill: transparent;
	}
}

.dot {
	animation: 1s blink infinite;
	fill: var(--text-chat);
}

.dot:nth-child(2) {
	animation-delay: 250ms;
}

.dot:nth-child(3) {
	animation-delay: 500ms;
}
