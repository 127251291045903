.hoverRoll {
	display: inline-block;
	vertical-align: top;
	cursor: default;
	text-align: left;
	box-sizing: border-box;
	position: relative;
	width: 100%;
	contain: paint;
}

.hoverRoll.forceHover:not(.disabled) .default,
.hoverRoll:hover:not(.disabled) .default {
	transform: translate3d(0, -107%, 0);
	opacity: 0;
	user-select: none;
}

.hoverRoll.forceHover:not(.disabled) .hovered,
.hoverRoll:hover:not(.disabled) .hovered {
	transform: translatez(0);
	opacity: 1;
}

.default,
.hovered {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	transform-style: preserve-3d;
	pointer-events: none;
	width: 100%;
	transition: all .22s ease;
}

.hovered {
	opacity: 0;
	transform: translate3d(0, 107%, 0);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
