.message .buttons {
  opacity: 0;
  pointer-events: none;
}

.message:hover .buttons, .message.selected .buttons, .message .buttons.emojiPickerOpen {
  opacity: 1;
  pointer-events: auto;
}

.message .timestampVisibleOnHover {
  opacity: 0;
  pointer-events: none;
}

.message:hover .timestampVisibleOnHover {
  opacity: 1;
  pointer-events: auto;
}

.messageSent {
  color: var(--text-chat);
}

.messageSending {
  color: var(--text-chat-muted);
}

.messageSending a {
  opacity: .7;
}

.messageFailed, .messageFailed a {
  color: var(--status-danger);
}

.container {
  text-indent: 0;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-auto-flow: row;
  row-gap: .25rem;
  min-width: 0;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 0;
  padding-top: .125rem;
  padding-bottom: .125rem;
  display: grid;
  position: relative;
}

.container:empty {
  display: none;
}

.container > * {
  place-self: start;
}

.repliedMessage {
  --reply-spacing: 4px;
  margin-bottom: var(--reply-spacing);
}

.repliedMessage:before {
  --avatar-size: 40px;
  --gutter: 16px;
  --spine-width: 2px;
  content: "";
  box-sizing: border-box;
  top: 50%;
  bottom: 0;
  right: 100%;
  left: calc(-1 * (.5 * var(--avatar-size)  + var(--gutter)));
  margin-right: var(--reply-spacing);
  margin-top: calc(-.5 * var(--spine-width));
  margin-left: calc(-.5 * var(--spine-width));
  border-color: var(--text-chat-muted);
  border-width: var(--spine-width) 0 0 var(--spine-width);
  border-style: solid;
  border-top-left-radius: 6px;
  margin-bottom: calc(.125rem - 4px);
  display: block;
  position: absolute;
}

.repliedTextPreview {
  flex: 0 auto;
  display: flex;
  overflow: hidden;
}

.repliedTextPreview, .repliedTextContent {
  -webkit-line-clamp: 1;
  word-break: break-all;
  width: 100%;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-box-orient: vertical;
  display: -webkit-inline-box !important;
}

.messageContent {
  --message-margin-left-content: calc(40px + 16px + 16px);
  -webkit-user-select: text;
  user-select: text;
  margin-left: calc(-1 * var(--message-margin-left-content));
  padding-left: var(--message-margin-left-content);
  pointer-events: none;
  overflow: hidden;
}
