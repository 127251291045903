.popout {
  pointer-events: auto;
  transition: none;
}

.popouts {
  pointer-events: none;
  z-index: var(--z-index-popout);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none !important;
}
