.button {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 96px;
  height: 38px;
  min-height: 38px;
  padding: 2px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  transition: background-color .3s, color .3s, transform .1s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.button:active:not(.ghost):not(:disabled) {
  opacity: .8;
  transform: translateY(1px);
}

.button.small {
  min-width: 60px !important;
  height: 32px !important;
  min-height: 32px !important;
}

.button.fitContent {
  min-width: 0 !important;
  padding: 2px 8px !important;
}

.button.fitContainer {
  width: 100%;
}

.button.brand {
  background-color: var(--brand-primary);
  color: var(--brand-primary-fill);
}

.button.brand:hover:not(:disabled) {
  background-color: var(--brand-secondary);
}

.button.brand:focus-visible:not(:disabled) {
  background-color: var(--brand-secondary);
}

.button.primary {
  background-color: var(--button-primary-fill);
  color: var(--button-primary-text);
}

.button.primary:hover:not(:disabled) {
  background-color: var(--button-primary-active-fill);
}

.button.primary:focus-visible:not(:disabled) {
  background-color: var(--button-primary-active-fill);
}

.button.secondary {
  background-color: var(--button-secondary-fill);
  color: var(--button-secondary-text);
}

.button.secondary:hover:not(:disabled) {
  background-color: var(--button-secondary-active-fill);
  color: var(--button-secondary-active-text);
}

.button.secondary:focus-visible:not(:disabled) {
  background-color: var(--button-secondary-active-fill);
  color: var(--button-secondary-active-text);
}

.button.danger {
  background-color: var(--button-danger-fill);
  color: var(--button-danger-text);
}

.button.danger:hover:not(:disabled) {
  background-color: var(--button-danger-active-fill);
}

.button.danger:focus-visible:not(:disabled) {
  background-color: var(--button-danger-active-fill);
}

.button.danger-outline {
  border: var(--button-danger-outline-border);
  color: var(--button-danger-outline-text);
  background-color: #0000;
  transition: color .3s, background-color .3s, border-color .3s;
}

.button.danger-outline:active:not(:disabled), .button.danger-outline:hover:not(:disabled) {
  background-color: var(--button-danger-outline-active-fill);
  border-color: var(--button-danger-outline-active-border);
}

.button.ghost {
  color: var(--button-ghost-text);
  background-color: #0000;
}

.button.ghost:hover:not(:disabled) {
  background-color: #0000;
  text-decoration: underline;
}

.button.ghost:focus-visible:not(:disabled) {
  background-color: #0000;
  text-decoration: underline;
}

.button.inverted {
  background-color: var(--button-inverted-fill);
  color: var(--button-inverted-text);
}

.spinner {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.spinnerInner {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: auto;
  display: flex;
  position: relative;
}

.spinnerItem {
  opacity: .3;
  background-color: #fff;
  border-radius: 4px;
  width: 6px;
  height: 6px;
  margin-right: 2px;
  animation: 1.4s ease-in-out infinite spinnerPulsingEllipsis;
  display: inline-block;
}

.spinnerItem:nth-of-type(2) {
  animation-delay: .2s;
}

.spinnerItem:nth-of-type(3) {
  animation-delay: .4s;
}

.iconWrapper {
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
}

.spinnerWrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.grid {
  width: 100%;
  height: 100%;
  display: grid;
}

@keyframes spinnerPulsingEllipsis {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: .3;
    transform: scale(.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
