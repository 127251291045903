.footer {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.content {
  flex-direction: column;
  gap: 16px;
  display: flex;
}
