.container {
	margin-top: 8px;
	max-width: 100%;
	display: flex;
	flex-direction: column;
}

.wrapper {
	width: fit-content;
	max-width: 100%;
}

.attachment {
	padding: 16px;
	border-radius: 8px;
	width: 432px;
	max-width: 100%;
	background-color: var(--background-primary);
	display: flex;
	align-items: center;
}

.small {
	width: 256px;
}

.icon {
	margin-right: 8px;
	height: 40px;
	flex-shrink: 0;
	color: var(--brand-primary-light);
}

.content {
	flex: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.filename {
	color: var(--text-link);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.link {
	color: var(--text-link);
	text-decoration: none;
}

.link:hover {
	text-decoration: underline;
}

.metadata {
	line-height: 16px;
	font-size: 12px;
	color: var(--text-primary-muted);
}
