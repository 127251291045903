.reactionsGrid {
  flex-wrap: wrap;
  gap: .25rem;
  padding-block-start: .25rem;
  padding-block-end: .25rem;
  display: flex;
}

.reactionContainer {
  position: relative;
}

.reactionButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--background-primary);
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: .5rem;
  align-items: center;
  padding: .375rem .5rem;
  transition: background-color .1s, border-color .1s;
  display: flex;
}

.reactionButton:hover, .reactionMe .reactionButton {
  border-color: var(--brand-primary);
  background-color: #5966f326;
}

.reactionGrid {
  align-items: center;
  gap: .375rem;
  display: flex;
}

.emoji {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  display: block;
}

.countWrapper {
  color: var(--text-tertiary);
  min-width: 9px;
  height: 1rem;
  font-weight: 600;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.reactionMe .countWrapper {
  color: var(--brand-primary-light);
}
