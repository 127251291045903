.skinTonePickerButton {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.cursorPointer {
	cursor: pointer;
}

.cursorText {
	cursor: text;
}

.skinTonePickerOptions {
	position: absolute;
	top: 0;
	right: 11px;
	display: flex;
	flex-direction: column;
	width: auto;
	height: 192px;
	background-color: var(--background-secondary);
	border: 1px solid var(--background-tertiary);
	border-radius: 4px;
}

.skinTonePickerItem {
	width: 32px;
	height: 32px;
	padding: 4px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	transition: background-color 0.1s ease-in-out;
}

.skinTonePickerItemImage {
	width: 24px;
	height: 24px;
}

.emojiPicker {
	position: relative;
	display: grid;
	grid-template-columns: 48px auto;
	grid-template-rows: auto 1fr auto;
	width: auto;
	height: 100%;
	overflow: hidden;
}

.bodyWrapper {
	display: grid;
	position: relative;
	grid-column: 2 / 3;
	grid-row: 2 / 3;
	grid-template-rows: auto 1fr;
}

.emojiPickerListWrapper {
	position: relative;
	grid-row: 2 / 3;
	overflow: hidden;
}

.listWrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.list {
	height: 100%;
}

.header {
	display: flex;
	align-items: center;
	position: relative;
	grid-column: 1 / 3;
	padding: 0 16px 16px;
	z-index: 100;
}

.searchBar {
	display: flex;
	flex: 1;
	margin-right: 12px;
	box-sizing: border-box;
	border-radius: 4px;
	overflow: hidden;
	background-color: var(--background-tertiary);
}

.searchBarInner {
	display: flex;
	position: relative;
	flex: 1 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 1px;
	box-sizing: border-box;
}

.searchBarInput {
	flex: 1;
	min-width: 48px;
	height: 30px;
	margin: 1px;
	padding: 0 8px;
	box-sizing: border-box;
	font-size: 16px;
	line-height: 32px;
	background: transparent;
	border: none;
	resize: none;
	appearance: none;
	color: var(--text-tertiary);
}

.searchBarInput::placeholder {
	color: var(--text-primary-muted);
}

.iconLayout {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	box-sizing: border-box;
	cursor: text;
}

.iconContainer {
	position: relative;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
}

.icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transform: rotate(90deg);
	z-index: 2;
	transition: transform 0.1s ease-out, opacity 0.1s ease-out;
	color: var(--text-tertiary-muted);
}

.icon.visible {
	transform: rotate(0);
	opacity: 1;
}

.iconSize {
	width: 24px;
	height: 24px;
}

.inspector {
	display: flex;
	align-items: center;
	position: relative;
	grid-column: 2 / 3;
	grid-row: 3 / 4;
	height: 48px;
	padding: 0 16px;
	box-sizing: border-box;
	background-color: var(--background-primary);
	overflow: hidden;
}

.inspectorEmoji {
	width: 28px;
	height: 28px;
}

.inspectorText {
	margin-left: 8px;
}

.categoryList {
	display: grid;
	position: absolute;
	top: 50px;
	bottom: 0;
	left: 0;
	right: 0;
	width: 48px;
	overflow: hidden;
	background: var(--background-primary);
	grid-template-rows: 1fr auto;
	border-radius: 0 0 0 8px;
}

.listItems {
	display: flex;
	flex-direction: column;
	padding: 0 8px;
	flex: 1 1 auto;
	contain: layout;
}

.categoryListIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	margin-bottom: 4px;
	cursor: pointer;
	border-radius: 0.375rem;
	transition: background-color 0.2s, color 0.2s;
}

.categoryListIcon:hover {
	background-color: var(--background-modifier-hover);
	color: var(--text-primary);
}

.categoryListIconActive {
	background-color: var(--background-modifier-hover);
	color: var(--text-primary);
}

.textPrimaryMuted {
	color: var(--text-primary-muted);
}

.emojiGrid {
	display: grid;
	grid-template-columns: repeat(9, 1fr);
}

.emojiGridCategory {
	margin-bottom: 16px;
}

.categoryTitle {
	margin-bottom: 8px;
	font-size: 0.875rem;
	font-weight: bold;
	color: var(--text-primary-muted);
}

.emojiRenderer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 0.375rem;
	cursor: pointer;
	transition: background-color 0.2s, color 0.2s;
}

.emojiRenderer:hover {
	background-color: var(--background-modifier-selected);
}

.emojiImage {
	width: 40px;
	height: 40px;
}
