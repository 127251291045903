.clickable {
	cursor: pointer;
}

.clickable:hover .overlay:after,
.clickable:has(:focus-visible) .overlay:after {
	opacity: 0.4;
}

.overlay:after {
	content: '';
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border-radius: 9999px;
	background-color: hsl(0, 0%, 0%);
	opacity: 0;
	transition: opacity 0.2s ease;
}
