.actionButtons {
  display: none;
  position: absolute;
  top: 12px;
  right: 12px;
}

.pinnedMessage:hover .actionButtons {
  display: flex;
}

.jumpButton {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  color: var(--text-primary-muted);
  background-color: var(--background-primary);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  padding: 0 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  transition: color .2s;
  display: flex;
  position: relative;
}

.jumpButton:hover {
  color: var(--text-primary);
}

.closeIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: var(--text-primary-muted);
  transition: color .2s;
  display: block;
}

.closeIcon:hover {
  color: var(--text-primary);
}
