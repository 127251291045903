.gifPickerContainer {
  grid-template-rows: auto 1fr auto;
  height: 100%;
  padding-bottom: 16px;
  display: grid;
  position: relative;
  overflow: hidden;
}

.gifPickerMain {
  display: flex;
  position: relative;
  overflow: hidden;
}

.scrollArea {
  contain: layout;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: auto;
}

.searchBarContainer {
  z-index: 10;
  align-items: center;
  gap: 8px;
  padding: 0 16px 16px;
  display: flex;
  position: relative;
}

.searchBar {
  background-color: var(--background-tertiary);
  border-radius: .375rem;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.searchBarInputContainer {
  flex-wrap: wrap;
  flex: auto;
  padding: 1px;
  display: flex;
  position: relative;
}

.searchBarInput {
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 48px;
  height: 30px;
  color: var(--text-primary);
  background: none;
  border: none;
  flex: 1;
  margin: 1px;
  padding: 0 8px;
  font-size: 1rem;
  line-height: 2;
}

.searchBarInput::placeholder {
  color: var(--text-primary-muted);
}

.searchBarIconContainer {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.searchBarTitleWrapper {
  align-items: center;
  gap: 8px;
  display: flex;
}

.searchBarBackButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: var(--text-primary-muted);
  transition: color .1s ease-out;
}

.searchBarBackButton:hover {
  color: var(--text-primary);
}

.searchBarTitle {
  color: var(--text-tertiary);
  font-size: 1rem;
  font-weight: 600;
}

.cursorPointer {
  cursor: pointer;
}

.cursorText {
  cursor: text;
}

.searchBarIconWrapper {
  width: 20px;
  height: 20px;
  position: relative;
}

.icon {
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  color: var(--text-primary-muted);
  transition: transform .1s ease-out, opacity .1s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.visible {
  opacity: 1;
  transform: rotate(0);
}

.hidden {
  opacity: 0;
}

.grid {
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 16px;
  display: flex;
}

.column {
  flex-direction: column;
  flex: 1;
  gap: 10px;
  display: flex;
}

.gridItem {
  background-color: var(--background-secondary);
  cursor: pointer;
  border-radius: .375rem;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.favoriteButton {
  z-index: 1;
  color: #fff;
  transition: scale .2s, color .2s;
  position: absolute;
  top: 8px;
  left: 8px;
}

.favoriteButtonActive {
  color: #d8b36f;
}

.favoriteButtonActive:hover {
  scale: 1.2;
}

.favoriteButtonIcon {
  width: 28px;
  height: 28px;
}

.favoriteButton:hover {
  color: #d8b36f;
}

.gridItemBackdrop {
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: background-color .1s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.gridItemCategory .gridItemBackdrop {
  background-color: #0009;
}

.gridItemCategory:hover .gridItemBackdrop {
  background-color: #000c;
}

.gridItemGif:hover .gridItemBackdrop {
  background-color: #0006;
}

.gridItemFavorites .gridItemBackdrop {
  background-color: #443fd999;
}

.gridItemFavorites:hover .gridItemBackdrop {
  background-color: #443fd9cc;
}

.gridItemCategoryTitle {
  z-index: 2;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gridItemIcon {
  width: 20px;
  height: 20px;
}

.gridItemCategoryTitleText {
  text-shadow: 0 1px 1px #0009;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.gif {
  object-fit: cover;
  background-color: #0000;
  border-radius: 5px;
  width: 100%;
  display: block;
  position: relative;
}

.header {
  background-color: var(--background-primary);
  align-items: center;
  padding: 16px;
  display: flex;
}

.backButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: var(--text-primary-muted);
  margin-right: 16px;
  transition: color .1s ease-out;
}

.backButton:hover {
  color: var(--text-primary);
}

.header h2 {
  color: var(--text-primary);
  flex: 1;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.skeletonContainer {
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 16px;
  display: flex;
}

.skeletonItem {
  background: #e0e0e0;
  border-radius: .375rem;
  flex: 45%;
  height: 200px;
  animation: 1.5s infinite pulse;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}

.suggestionsContainer {
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  padding: 16px 16px 0;
  display: flex;
}

.suggestionTag {
  background-color: var(--background-tertiary);
  color: var(--text-primary);
  cursor: pointer;
  border: none;
  border-radius: 16px;
  padding: 8px 16px;
  font-size: 14px;
  transition: background-color .2s;
}

.suggestionTag:hover {
  background-color: var(--background-modifier-hover);
}

.suggestionTag:active {
  background-color: var(--background-modifier-selected);
}
