.popout {
	pointer-events: auto;
	transition: none;
}

.popouts {
	background: none !important;
	inset: 0;
	pointer-events: none;
	position: fixed;
	z-index: var(--z-index-popout);
}
