.theme-light .markup {
  font-weight: 500;
}

.blockquoteContainer {
  width: 100%;
  margin: 2px 0;
  display: flex;
}

.blockquoteDivider {
  background-color: var(--interactive-muted);
  border-radius: 4px;
  flex-shrink: 0;
  width: 4px;
}

.blockquoteContent {
  box-sizing: border-box;
  text-indent: 0;
  width: 100%;
  padding: 0 8px 0 12px;
}

.emoji {
  object-fit: contain;
  vertical-align: -.35em;
  cursor: pointer;
  width: 1.375em;
  height: 1.375em;
  display: inline-block;
  position: static;
}

.emoji.jumboable {
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
}

.hljs {
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  background: var(--custom-code-bg, var(--background-primary));
  border-radius: 4px;
  padding: .5em;
  display: block;
  overflow-x: auto;
}

.interactive {
  cursor: pointer;
  transition: background-color 50ms ease-out, color 50ms ease-out;
}

.interactive:hover {
  color: var(--markup-interactive-hover-text);
  background-color: var(--markup-interactive-hover-fill);
}

.markup .codeActions {
  display: none;
  position: absolute;
  top: 8px;
  right: 4px;
}

.markup .codeActions > div {
  cursor: pointer;
}

.markup .codeContainer {
  position: relative;
}

.markup .codeContainer:hover .codeActions {
  display: block;
}

.markup .no-webkit-scrollbar code {
  scrollbar-width: thin;
  scrollbar-color: var(--background-tertiary) var(--background-primary);
}

.link {
  color: var(--text-link);
  word-break: break-word;
  -webkit-text-decoration: var(--link-decoration);
  text-decoration: var(--link-decoration);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.markup blockquote pre {
  max-width: 100%;
}

.markup blockquote {
  box-sizing: border-box;
  text-indent: 0;
  max-width: 90%;
}

.markup code {
  text-indent: 0;
  white-space: pre-wrap;
  background: var(--custom-code-bg, var(--background-primary));
  font-size: .875rem;
  line-height: 1.125rem;
}

.markup code.inline {
  font-size: 85%;
  font-family: var(--font-mono);
  text-indent: 0;
  white-space: pre-wrap;
  border-radius: 4px;
  margin: -.2em 0;
  padding: 0 .2em;
}

.markup em {
  font-style: italic;
}

.markup h1 {
  margin: 16px 0 8px;
  font-size: 1.5rem;
}

.markup h1, .markup h2, .markup h3, .markup h4, .markup h5, .markup h6 {
  color: var(--header-primary);
  margin: 16px 0 4px;
  font-weight: 700;
  line-height: 1.375em;
}

.markup h1.inlineFormat, .markup h2.inlineFormat, .markup h3.inlineFormat, .markup h4.inlineFormat, .markup h5.inlineFormat, .markup h6.inlineFormat {
  font-weight: 600;
  font-size: inherit;
  white-space: normal;
  color: inherit;
  padding-right: 16px;
  line-height: 1.25em;
  display: inline;
  margin: 0 !important;
}

.markup h1:first-child, .markup h2:first-child {
  margin-top: 8px;
}

.markup h2 {
  margin: 16px 0 8px;
  font-size: 1.25rem;
}

.markup h3 {
  margin: 16px 0 8px;
  font-size: 1rem;
}

.markup h3:first-child, .markup h4:first-child, .markup h5:first-child, .markup h6:first-child {
  margin-top: 4px;
}

.markup li {
  white-space: break-spaces;
  margin-bottom: 4px;
}

.markup ol ol, .markup ul ol {
  margin-bottom: 0;
}

.markup ol {
  margin-left: calc(.4em + .6em * var(--totalCharacters));
  list-style-type: decimal;
}

.markup pre {
  box-sizing: border-box;
  font-family: var(--font-mono);
  white-space: pre-wrap;
  background-clip: border-box;
  border-radius: 4px;
  max-width: 90%;
  margin-top: 6px;
  padding: 0;
  font-size: .75rem;
  line-height: 1rem;
}

.markup small.inlineFormat:after {
  content: \\\' \\\';
}

.markup small:not(.inlineFormat) {
  color: var(--text-chat-muted);
  font-size: .8125rem;
  line-height: 1.11719rem;
  display: block;
}

.markup strong {
  font-weight: 700;
}

.markup ul ul, .markup ol ul {
  margin-bottom: 0;
  list-style-type: circle;
}

.markup ul {
  list-style-type: disc;
}

.markup ul, .markup ol {
  margin: 4px 0 0 16px;
  list-style-position: outside;
}

.markup ul.inlineFormat, .markup ol.inlineFormat {
  margin: 0;
  padding: 0 8px 0 0;
  display: inline;
}

.markup {
  white-space: pre-wrap;
  white-space: break-spaces;
  word-wrap: break-word;
  -webkit-user-select: text;
  user-select: text;
  color: var(--text-chat);
  font-size: 1rem;
  line-height: 1.5rem;
}

.markup.inlineFormat li li:before, .markup .inlineFormat li li:before {
  content: "•";
  padding: 0 4px 4px;
  font-size: .625rem;
  line-height: 1rem;
}

.markup.inlineFormat li, .markup .inlineFormat li {
  margin: 0;
  padding-right: 8px;
  display: inline;
}

.markup.inlineFormat li:before, .markup .inlineFormat li:before {
  content: "•";
  padding-right: 4px;
}

.mention {
  color: var(--markup-mention-text);
  background-color: var(--markup-mention-fill);
  unicode-bidi: plaintext;
  border-radius: 4px;
  padding: 0 2px;
  font-weight: 500;
}

.bio {
  font-size: 14px;
  line-height: 1.28571;
}

.tableContainer {
  border: 1px solid var(--background-tertiary);
  background: var(--background-secondary);
  border-radius: 8px;
  max-width: 100%;
  margin: 8px 0;
  padding: 1px;
  overflow-x: auto;
}

.table {
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin: 0;
  font-size: .925rem;
}

.tableCell {
  border: 1px solid var(--background-tertiary);
  text-align: left;
  background: var(--background-primary);
  vertical-align: middle;
  border-bottom: 0;
  border-right: 0;
  padding: 12px 16px;
  line-height: 1.5;
}

.tableCell:last-child {
  border-right: 1px solid var(--background-tertiary);
}

.table tbody tr:last-child .tableCell {
  border-bottom: 1px solid var(--background-tertiary);
}

.tableHeader {
  background: var(--background-secondary);
  color: var(--text-primary);
  border-bottom: 2px solid var(--background-tertiary);
  font-weight: 600;
}

.tableHeader:first-child {
  border-top-left-radius: 8px;
}

.tableHeader:last-child {
  border-top-right-radius: 8px;
}

.table tbody tr:nth-child(odd) .tableCell {
  background: var(--background-secondary);
}

.table tbody tr:nth-child(2n) .tableCell {
  background: var(--background-secondary-alt);
}

.table tbody tr:hover .tableCell {
  background: var(--background-modifier-hover);
  transition: background-color .15s;
}

.table tbody tr:last-child .tableCell:first-child {
  border-bottom-left-radius: 8px;
}

.table tbody tr:last-child .tableCell:last-child {
  border-bottom-right-radius: 8px;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.tableCell code.inline {
  background: var(--background-tertiary);
  color: var(--text-primary);
  border-radius: 4px;
  padding: .2em .4em;
}

.tableCell ul, .tableCell ol {
  margin: 0;
  padding-left: 20px;
}

.alert {
  border-left: 4px solid;
  border-radius: .375rem;
  align-items: center;
  min-height: 3rem;
  margin: .25rem 0;
  padding: 1rem;
  display: flex;
}

.alertIcon {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .75rem;
}

.alertContent {
  flex-grow: 1;
  min-width: 0;
}

.alertNote {
  color: var(--text-chat);
  background-color: #388bfd1a;
  border-left-color: #388bfd;
}

.alertNote .alertIcon {
  color: #388bfd;
}

.alertTip {
  color: var(--text-chat);
  background-color: #2ea0431a;
  border-left-color: #2ea043;
}

.alertTip .alertIcon {
  color: #2ea043;
}

.alertImportant {
  color: var(--text-chat);
  background-color: #aa00ff1a;
  border-left-color: #a0f;
}

.alertImportant .alertIcon {
  color: #a0f;
}

.alertWarning {
  color: var(--text-chat);
  background-color: #e399121a;
  border-left-color: #e39912;
}

.alertWarning .alertIcon {
  color: #e39912;
}

.alertCaution {
  color: var(--text-chat);
  background-color: #f851491a;
  border-left-color: #f85149;
}

.alertCaution .alertIcon {
  color: #f85149;
}

.timestamp {
  background-color: var(--background-modifier-hover);
  white-space: nowrap;
  border-radius: 3px;
  padding: 0 2px;
}

:root {
  --spoiler-overlay-color: #0003;
  --spoiler-overlay-hover-color: #0000004d;
  --spoiler-border-radius: 6px;
}

.spoiler {
  padding: 0 2px;
}

.blockSpoiler {
  padding: 8px;
}

.spoiler:not([data-revealed="true"]), .blockSpoiler:not([data-revealed="true"]) {
  cursor: pointer;
  background-color: var(--spoiler-overlay-color);
  border-radius: var(--spoiler-border-radius);
  position: relative;
  overflow: hidden;
}

.spoiler:not([data-revealed="true"]) .spoilerContent, .blockSpoiler:not([data-revealed="true"]) .spoilerContent {
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  color: #0000;
  filter: blur(5px);
  transition: opacity .2s;
}

.spoiler:not([data-revealed="true"]):hover, .blockSpoiler:not([data-revealed="true"]):hover {
  background-color: var(--spoiler-overlay-hover-color);
}

.spoiler[data-revealed="true"], .blockSpoiler[data-revealed="true"] {
  background-color: var(--background-modifier-hover);
  border-radius: var(--spoiler-border-radius);
}

.spoiler[data-revealed="true"] .spoilerContent, .blockSpoiler[data-revealed="true"] .spoilerContent {
  opacity: 1;
  filter: none;
  transition: opacity .2s, filter .2s;
}

.spoiler .spoiler, .blockSpoiler .spoiler, .spoiler .blockSpoiler, .blockSpoiler .blockSpoiler {
  border-radius: var(--spoiler-border-radius);
}

.blockSpoiler .spoilerContent > :first-child {
  margin-top: 0;
}

.blockSpoiler .spoilerContent > :last-child {
  margin-bottom: 0;
}
