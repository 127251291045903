.backdrop {
	position: fixed;
	inset: 0;
	background: hsl(0deg 0% 0%);
	opacity: 0.85;
	pointer-events: all;
	transform: translateZ(0);
}

.layer {
	position: fixed;
	inset: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.focusLock {
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 800px) {
	.focusLock {
		height: 100%;
		width: 100%;
	}
}

.root {
	position: relative;
	margin: 0 auto;
	max-height: 85vh;
	display: flex;
	flex-direction: column;
	background-color: var(--background-secondary);
	border: 1px solid var(--background-header-secondary);
	border-radius: 8px;
	box-shadow: 0 0 0 1px hsla(223, 7%, 20%, 0.08), 0 16px 40px hsla(0, 0%, 0%, 0.16);
	overflow: hidden;
	pointer-events: all;
}

@media screen and (max-width: 800px) {
	.root {
		border-radius: 0;
		height: 100%;
		max-height: 100%;
		width: 100%;
	}
}

.root.default {
	min-height: 400px;
	width: 600px;
}

.root.small {
	min-height: 200px;
	width: 440px;
}

.root.large {
	height: 860px;
	width: 800px;
}

.layout {
	flex: 0 0 auto;
	position: relative;
	z-index: 1;
	overflow-x: hidden;
	padding: 16px;
}

.layout.header {
	display: flex;
	flex-direction: column;
	gap: 14px;
}

.layout.header.dark {
	background-color: var(--background-secondary);
}

.layout.footer {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-flow: row-reverse nowrap;
	background-color: var(--background-secondary);
	box-shadow: 0 -1px 0 var(--background-header-secondary);
}

.headerInner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.headerText h3 {
	color: var(--text-primary);
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
}

.headerInner button {
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	color: var(--text-primary-muted);
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 0.2s ease-in-out;
}

.headerInner button:hover {
	color: var(--text-primary);
	opacity: 1;
}

.content {
	flex: 1 1 auto;
	height: 100%;
	padding: 0 16px 16px 16px;
}
