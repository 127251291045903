.hoverRoll {
  vertical-align: top;
  cursor: default;
  text-align: left;
  box-sizing: border-box;
  contain: paint;
  width: 100%;
  display: inline-block;
  position: relative;
}

.hoverRoll.forceHover:not(.disabled) .default, .hoverRoll:hover:not(.disabled) .default {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  transform: translate3d(0, -107%, 0);
}

.hoverRoll.forceHover:not(.disabled) .hovered, .hoverRoll:hover:not(.disabled) .hovered {
  opacity: 1;
  transform: translateZ(0);
}

.default, .hovered {
  white-space: nowrap;
  text-overflow: ellipsis;
  transform-style: preserve-3d;
  pointer-events: none;
  width: 100%;
  transition: all .22s;
  display: block;
  overflow: hidden;
}

.hovered {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 107%, 0);
}
