.gifPickerContainer {
	position: relative;
	display: grid;
	height: 100%;
	grid-template-rows: auto 1fr auto;
	overflow: hidden;
	padding-bottom: 16px;
}

.gifPickerMain {
	position: relative;
	display: flex;
	overflow: hidden;
}

.scrollArea {
	overflow: auto;
	width: 100%;
	height: 100%;
	min-height: 0;
	contain: layout;
}

.searchBarContainer {
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
	padding: 0 16px 16px;
	gap: 8px;
}

.searchBar {
	display: flex;
	flex: 1;
	overflow: hidden;
	border-radius: 0.375rem;
	background-color: var(--background-tertiary);
}

.searchBarInputContainer {
	position: relative;
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	padding: 1px;
}

.searchBarInput {
	margin: 1px;
	height: 30px;
	min-width: 48px;
	flex: 1;
	resize: none;
	appearance: none;
	border: none;
	background: transparent;
	padding: 0 8px;
	font-size: 1rem;
	color: var(--text-primary);
	line-height: 2;
}

.searchBarInput::placeholder {
	color: var(--text-primary-muted);
}

.searchBarIconContainer {
	display: flex;
	height: 32px;
	width: 32px;
	align-items: center;
	justify-content: center;
}

.searchBarTitleWrapper {
	display: flex;
	align-items: center;
	gap: 8px;
}

.searchBarBackButton {
	cursor: pointer;
	width: 24px;
	height: 24px;
	color: var(--text-primary-muted);
	transition: color 0.1s ease-out;
}

.searchBarBackButton:hover {
	color: var(--text-primary);
}

.searchBarTitle {
	font-size: 1rem;
	font-weight: 600;
	color: var(--text-tertiary);
}

.cursorPointer {
	cursor: pointer;
}

.cursorText {
	cursor: text;
}

.searchBarIconWrapper {
	position: relative;
	height: 20px;
	width: 20px;
}

.icon {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	transition: transform 0.1s ease-out, opacity 0.1s ease-out;
	transform: rotate(90deg);
	color: var(--text-primary-muted);
}

.visible {
	transform: rotate(0);
	opacity: 1;
}

.hidden {
	opacity: 0;
}

.grid {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 0 16px;
}

.column {
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 10px;
}

.gridItem {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 0.375rem;
	background-color: var(--background-secondary);
	cursor: pointer;
	width: 100%;
	overflow: hidden;
}

.favoriteButton {
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 1;
	color: white;
	transition: scale 0.2s, color 0.2s;
}

.favoriteButtonActive {
	color: hsl(39, 57%, 64%);
}

.favoriteButtonActive:hover {
	scale: 1.2;
}

.favoriteButtonIcon {
	width: 28px;
	height: 28px;
}

.favoriteButton:hover {
	color: hsl(39, 57%, 64%);
}

.gridItemBackdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: background-color 0.1s ease-out;
	z-index: 1;
}

.gridItemCategory .gridItemBackdrop {
	background-color: hsla(0, 0%, 0%, 0.6);
}

.gridItemCategory:hover .gridItemBackdrop {
	background-color: hsla(0, 0%, 0%, 0.8);
}

.gridItemGif:hover .gridItemBackdrop {
	background-color: hsla(0, 0%, 0%, 0.4);
}

.gridItemFavorites .gridItemBackdrop {
	background-color: hsla(242, 67%, 55%, 0.6);
}

.gridItemFavorites:hover .gridItemBackdrop {
	background-color: hsla(242, 67%, 55%, 0.8);
}

.gridItemCategoryTitle {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	pointer-events: none;
	gap: 4px;
}

.gridItemIcon {
	width: 20px;
	height: 20px;
}

.gridItemCategoryTitleText {
	font-size: 16px;
	line-height: 16px;
	font-weight: 600;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
}

.gif {
	position: relative;
	display: block;
	width: 100%;
	object-fit: cover;
	background-color: transparent;
	border-radius: 5px;
}

.header {
	display: flex;
	align-items: center;
	padding: 16px;
	background-color: var(--background-primary);
}

.backButton {
	margin-right: 16px;
	cursor: pointer;
	width: 24px;
	height: 24px;
	color: var(--text-primary-muted);
	transition: color 0.1s ease-out;
}

.backButton:hover {
	color: var(--text-primary);
}

.header h2 {
	flex: 1;
	margin: 0;
	font-size: 1.25rem;
	font-weight: 600;
	color: var(--text-primary);
}

.skeletonContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 0 16px;
}

.skeletonItem {
	flex: 1 1 45%;
	height: 200px;
	background: hsl(0, 0%, 88%);
	border-radius: 0.375rem;
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		background-color: hsl(0, 0%, 88%);
	}
	50% {
		background-color: hsl(0, 0%, 94%);
	}
	100% {
		background-color: hsl(0, 0%, 88%);
	}
}

.suggestionsContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	padding: 16px 16px 0;
	justify-content: center;
}

.suggestionTag {
	padding: 8px 16px;
	border: none;
	border-radius: 16px;
	background-color: var(--background-tertiary);
	color: var(--text-primary);
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.2s ease;
}

.suggestionTag:hover {
	background-color: var(--background-modifier-hover);
}

.suggestionTag:active {
	background-color: var(--background-modifier-selected);
}
