.container {
  flex-direction: column;
  max-width: 100%;
  margin-top: 8px;
  display: flex;
}

.wrapper {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
}

.attachment {
  background-color: var(--background-primary);
  border-radius: 8px;
  align-items: center;
  width: 432px;
  max-width: 100%;
  padding: 16px;
  display: flex;
}

.small {
  width: 256px;
}

.icon {
  height: 40px;
  color: var(--brand-primary-light);
  flex-shrink: 0;
  margin-right: 8px;
}

.content {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.filename {
  color: var(--text-link);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link {
  color: var(--text-link);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.metadata {
  color: var(--text-primary-muted);
  font-size: 12px;
  line-height: 16px;
}
