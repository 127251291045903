.actionButtons {
	display: none;
	position: absolute;
	top: 12px;
	right: 12px;
}

.pinnedMessage:hover .actionButtons {
	display: flex;
}

.jumpButton {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	font-weight: 500;
	line-height: 20px;
	padding: 0 8px;
	font-size: 11px;
	margin-right: 4px;
	border-radius: 4px;
	text-align: center;
	color: var(--text-primary-muted);
	background-color: var(--background-primary);
	transition: color 0.2s;
}

.jumpButton:hover {
	color: var(--text-primary);
}

.closeIcon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	color: var(--text-primary-muted);
	transition: color 0.2s;
}

.closeIcon:hover {
	color: var(--text-primary);
}
