.reactionsGrid {
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	padding-block: 0.25rem;
}

.reactionContainer {
	position: relative;
}

.reactionButton {
	appearance: none;
	background: var(--background-primary);
	border: 1px solid transparent;
	border-radius: 0.5rem;
	cursor: pointer;
	padding: 0.375rem 0.5rem;
	transition: background-color 0.1s ease, border-color 0.1s ease;
	display: flex;
	align-items: center;
}

.reactionButton:hover {
	background-color: hsla(235, 86%, 65%, 0.15);
	border-color: var(--brand-primary);
}

.reactionMe .reactionButton {
	background-color: hsla(235, 86%, 65%, 0.15);
	border-color: var(--brand-primary);
}

.reactionGrid {
	display: flex;
	align-items: center;
	gap: 0.375rem;
}

.emoji {
	width: 1rem;
	height: 1rem;
	display: block;
	flex-shrink: 0;
}

.countWrapper {
	color: var(--text-tertiary);
	font-weight: 600;
	min-width: 9px;
	line-height: 1;
	position: relative;
	overflow: hidden;
	height: 1rem;
}

.reactionMe .countWrapper {
	color: var(--brand-primary-light);
}
