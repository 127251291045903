.footer {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
