.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 16px;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	height: 38px;
	min-height: 38px;
	min-width: 96px;
	border-radius: 8px;
	cursor: pointer;
	appearance: none;
	text-decoration: none;
	position: relative;
	overflow: hidden;
	transition: background-color 0.3s ease, color 0.3s ease, transform 0.1s ease;
}

.button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.button:active:not(.ghost):not(:disabled) {
	opacity: 0.8;
	transform: translateY(1px);
}

.button.small {
	height: 32px !important;
	min-height: 32px !important;
	min-width: 60px !important;
}

.button.fitContent {
	min-width: 0 !important;
	padding: 2px 8px !important;
}

.button.fitContainer {
	width: 100%;
}

.button.brand {
	background-color: var(--brand-primary);
	color: var(--brand-primary-fill);
}

.button.brand:hover:not(:disabled),
.button.brand:focus-visible:not(:disabled) {
	background-color: var(--brand-secondary);
}

.button.primary {
	background-color: var(--button-primary-fill);
	color: var(--button-primary-text);
}

.button.primary:hover:not(:disabled),
.button.primary:focus-visible:not(:disabled) {
	background-color: var(--button-primary-active-fill);
}

.button.secondary {
	background-color: var(--button-secondary-fill);
	color: var(--button-secondary-text);
}

.button.secondary:hover:not(:disabled),
.button.secondary:focus-visible:not(:disabled) {
	background-color: var(--button-secondary-active-fill);
	color: var(--button-secondary-active-text);
}

.button.danger {
	background-color: var(--button-danger-fill);
	color: var(--button-danger-text);
}

.button.danger:hover:not(:disabled),
.button.danger:focus-visible:not(:disabled) {
	background-color: var(--button-danger-active-fill);
}

.button.danger-outline {
	background-color: transparent;
	border: var(--button-danger-outline-border);
	color: var(--button-danger-outline-text);
	transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.danger-outline:active:not(:disabled),
.button.danger-outline:hover:not(:disabled) {
	background-color: var(--button-danger-outline-active-fill);
	border-color: var(--button-danger-outline-active-border);
}

.button.ghost {
	background-color: transparent;
	color: var(--button-ghost-text);
}

.button.ghost:hover:not(:disabled),
.button.ghost:focus-visible:not(:disabled) {
	background-color: transparent;
	text-decoration: underline;
}

.button.inverted {
	background-color: var(--button-inverted-fill);
	color: var(--button-inverted-text);
}

.spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.spinnerInner {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 28px;
	height: auto;
}

.spinnerItem {
	display: inline-block;
	width: 6px;
	height: 6px;
	margin-right: 2px;
	background-color: hsl(0, 0%, 100%);
	border-radius: 4px;
	opacity: 0.3;
	animation: spinnerPulsingEllipsis 1.4s ease-in-out infinite;
}

.spinnerItem:nth-of-type(2) {
	animation-delay: 0.2s;
}

.spinnerItem:nth-of-type(3) {
	animation-delay: 0.4s;
}

.iconWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
}

.spinnerWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

.grid {
	display: grid;
	width: 100%;
	height: 100%;
}

@keyframes spinnerPulsingEllipsis {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
