.scroller::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

.scroller:-webkit-scrollbar-corner {
	background-color: transparent;
}

.scroller::-webkit-scrollbar-thumb {
	background-color: var(--background-header-secondary);
	min-height: 40px;
}

.scrollerWithBottomGutter::-webkit-scrollbar-track {
	margin-bottom: 8px;
}

.scroller::-webkit-scrollbar-thumb,
.scroller::-webkit-scrollbar-track {
	border: 4px solid transparent;
	background-clip: padding-box;
	border-radius: 8px;
}

.scroller::-webkit-scrollbar-track {
	background-color: var(--background-secondary);
}
